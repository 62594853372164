var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DateRangePicker',{on:{"change-date":_vm.onDateChanged}}),_c('LazyListDataProvider',{ref:"dataProvider",attrs:{"immediate":true,"data-source":_vm.getDataSource,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var logbookListData = ref.data;
return [(logbookListData.length)?_c('ul',{staticClass:"logbook-cards"},_vm._l((logbookListData),function(data,index){return _c('CardListItem',{key:index,staticClass:"mb-6",attrs:{"tag":"li","logbook":data,"index":_vm.getAbsoluteIndex(index)},on:{"delete:success":_vm.onRefreshList}})}),1):_vm._e()]}},{key:"pending",fn:function(){return [_c('CardListItemSkeleton',{staticClass:"my-2"})]},proxy:true},{key:"error",fn:function(ref){
var message = ref.message;
return [_c('p',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"end",fn:function(ref){
var hasNoData = ref.hasNoData;
var hasAtLeastOneData = ref.hasAtLeastOneData;
return [_c('p',{staticClass:"p-6 rounded text-gray-600 text-center bg-gray-200"},[(hasNoData)?[_vm._v(" Data Tidak Tersedia ")]:(hasAtLeastOneData)?[_vm._v(" Akhir Dari Data ")]:_vm._e()],2)]}}])}),_c('div',{staticClass:"fixed left-0 right-0 w-full flex justify-center items-center",staticStyle:{"bottom":"5rem"}},[_c('button',{staticClass:"floating-action-button bg-blue-500",on:{"click":_vm.onCreateNewLogbook}},[_vm._v(" + Buat Laporan Baru ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }